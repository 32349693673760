import axios from 'axios';

export const getAssistantConfig = async (botId) => {
  try {
    const { data } = await axios.get(`/api/client-configuration/${botId}`);
    return data;
  } catch (error) {
    console.error('Error fetching assistant config:', error);
    throw error;
  }
};
