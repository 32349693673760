import ActiveDirectoryStore from './ProvidersStore/activeDirectoryStore';
import FirebaseStore from './ProvidersStore/firebaseStore';
import OktaStore from './ProvidersStore/oktaStore';

let serviceInstance = null;
class authProviderService {

  createProvider(type, config) {
    if (type === 'ACTIVE_DIRECTORY') {
       return new ActiveDirectoryStore(config);
    } else if (type === 'FIREBASE') {
      return new FirebaseStore(config);
    } else if (type === 'OKTA') {
      return new OktaStore(config);
    } else {
      throw new Error(`Unsupported provider type: ${type}`);
    }
  }
}

export default function createAuthProviderService() {
  if (!serviceInstance) {
    console.log('Creating new instance of authProviderService');
    serviceInstance = new authProviderService();
  }
  return serviceInstance;
};
