import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { finishedLoginAtom } from '../../atoms/users';
import Login from '../../components/Login/Login';
import { validateLoginPassword } from '../../api/auth';
import Snackbar from '../../components/Snackbar/Snackbar';
import { SnackbarProvider, useSnackbar } from 'notistack';

function LoginPasswordPage({ botId }) {
  const [, setFinishedLogin] = useAtom(finishedLoginAtom);
  const [loginInfo, setLoginInfo] = useState({ password: '' });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setLoginInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleLoginPassword = async (e) => {
    e.preventDefault();
    try {
      const isCorrectPassword = await validateLoginPassword(loginInfo.password, botId);
      if (!isCorrectPassword) {
        console.log('Password is incorrect, please try again');
        enqueueSnackbar(`Password is incorrect, please try again`, {
          content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
        return;
      }
      setFinishedLogin(true);
    } catch (err) {
      throw new Error('Failed to login with password:', err);
    }
  };

  return <Login loginForm={loginInfo} handleLogin={handleLoginPassword} handleChange={handleChange} />;
}

export default LoginPasswordPage;
