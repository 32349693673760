import './SourcePopUp.scss';
import React, { useRef } from 'react';
import { ReactComponent as ClosePopUpBtn } from '../../assets/close-pop-up.svg';
import { ReactComponent as TitleImg } from '../../assets/pop-up-title-img.svg';

function SourcePopUp({ sourcePopUpData, setSourcePopUpData }) {
  const sources = sourcePopUpData;
  const popupContentRef = useRef(null);

  const handleClosePopUp = (e) => {
    // Check if the click is outside the popup content
    if (popupContentRef.current && !popupContentRef.current.contains(e.target)) {
      setSourcePopUpData(null);
    }
  };

  return (
    <div className="popup-overlay" onClick={handleClosePopUp}>
      <div className="popup" ref={popupContentRef} style={{ gap: '20px' }}>
        {sources.map((source, index) => {
          if (source.content.includes('⚫')) source.content = source.content.replace(/⚫/g, '');
          else if (source.content.includes('original_content:'))
            source.content = source.content.split('original_content:')[1];
          return (
            <div key={index}>
              <div className="popup-header">
                <div className="title-container">
                  <a href={source.url} target="_blank" rel="noreferrer">
                    <TitleImg />
                  </a>
                  <h2>{`${source.name} - ${source.title.split('.')[0]}`}</h2>
                </div>

                <ClosePopUpBtn onClick={() => setSourcePopUpData(null)} className="close-btn" />
              </div>
              <div className="popup-content">
                {' '}
                {source.content.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index !== source.content.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </div>
              {index !== sources.length - 1 && <br />}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SourcePopUp;
