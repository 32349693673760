import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import App from './App';

const AppWrapper = () => {
  const { botId } = useParams();  // Capture the botId from the URL

  return <App botId={botId} />;  // Pass botId as a prop to App
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <SnackbarProvider maxSnack={1}>
      <Routes>
        {/* Route to capture botId and pass it to App component */}
        <Route path="/:botId/*" element={<AppWrapper />} />
      </Routes>
    </SnackbarProvider>
  </Router>
);
