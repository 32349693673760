import { useEffect, useState } from 'react';
import axios from '../../configs/axios.js';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { FirebaseProvider } from '../../components/LoginProviders/firebase/firebase';
import { ActiveDirectoryProvider } from '../../components/LoginProviders/activeDirectory/activeDirectory';
import { OktaProvider } from '../../components/LoginProviders/okta/okta';
import { userAtom, loginConfigAtom } from '../../atoms/users';
import { validateUserPermission, loginUser } from '../../api/user';

function LoginPage() {
  const { botId } = useParams();
  const [config] = useAtom(loginConfigAtom);
  const [, setUser] = useAtom(userAtom);
  const [LoginProvider, setLoginProvider] = useState(null);

  useEffect(() => {
    console.log('Login Provider:', config.type);

    const loginProviderMap = {
      FIREBASE: FirebaseProvider,
      ACTIVE_DIRECTORY: ActiveDirectoryProvider,
      OKTA: OktaProvider,
    };
    const selectedProvider = loginProviderMap[config.type];

    setLoginProvider(() => selectedProvider);
  }, [config]);

  const handleLoginProvider = async (userLoginInfo) => {
    try {
      const isPermitted = await validateUserPermission(userLoginInfo.email, botId);
      if (!isPermitted) {
        console.log('User is not permitted to login assistant bot');
        return false;
      }

      // update the header token with provider token
      axios.updateAxiosToken(userLoginInfo.providerToken);

      // login to jeen and create jeen token
      const userLogin = await loginUser(userLoginInfo.email);
      if (!userLogin) {
        console.log('Failed to create jeen token');
        return false;
      }

      // destrcut params of backend response
      const { user: apiUser } = userLogin;

      setUser({
        id: apiUser.id,
        email: apiUser.email,
        userName: apiUser.name,
        lang: 'english',
        // token: apiToken,
        providerToken: userLoginInfo.providerToken,
        modelAvailable: apiUser.modelAvailable,
        tokenLimit: apiUser.tokenLimit,
        tokenUsed: apiUser.tokenUsed,
        wordsUsed: apiUser.wordsUsed,
      });

      // update the header token with jeen token
      // axios.updateAxiosToken(apiToken);
      return true;
    } catch (err) {
      throw new Error('Failed to handle user login to jeen:', err);
    }
  };

  const loginUserWithData = async () => {
    try {
      // access playground page and get user necessary data
      // navigate(`/:${botData.id}`);
    } catch (err) {
      console.log('Failed to login user and fetch data:', err);
    }
  };

  return LoginProvider ? (
    <LoginProvider handleLogin={handleLoginProvider} loginUserWithData={loginUserWithData} />
  ) : (
    <div>Loading Provider...</div>
  );
}

export default LoginPage;
