import './App.css';
import './style.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HomePage from './pages/HomePage/HomePage';
import MainPage from './pages/MainPage/MainPage';
import setColors from './hooks/setColors.js';
import { useAtom } from 'jotai';
import { finishedLoginAtom, loginConfigAtom, userAtom } from './atoms/users';
import { SessionStorageHandler } from './actions/auth.actions.js';
import { propertiesAtom, botDataAtom } from './atoms/general';
import { getProperties } from './api/properties';
import { isLoginRedirect, OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';
import { getAssistantConfig } from './api/settings';
import { LOGIN_CONFIG_TYPE, LOGIN_METHOD_TYPE } from './constants/enums';
import { setAssistantLoginConfig as getAssistantLoginConfig } from './services/loginConfigService.js';

const oktaAuth = new OktaAuth({
  issuer: 'https://ormat.okta.com/oauth2/default',
  clientId: '0oaz8rf9n1JY013Ed2p7',
  redirectUri: window.location.origin + '/login/callback',
});

const App = ({ botId }) => {
  const [, setProperties] = useAtom(propertiesAtom);
  const [, setFinishedLogin] = useAtom(finishedLoginAtom);
  const [loginConfig, setLoginConfig] = useAtom(loginConfigAtom);
  const [botData, setBotData] = useState(null);
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const sessionStorageHandler = SessionStorageHandler();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  useEffect(() => {
    const setSettings = async () => {
      setFinishedLogin(false);
      const settings = await getProperties();
      const isLoggedIn = sessionStorageHandler.getItem("assistant-login-config");
      const botConfig = await getAssistantConfig(botId);
      if (!isLoggedIn) {
        const { login_method: method, login_provider: provider } = botConfig.login;
        const config = await getAssistantLoginConfig(method, provider, botId);
        setLoginConfig(config);

      }

      await setColors(settings.colors);
      setProperties(settings);
      setBotData(botConfig);
    };
    setSettings();
  }, [botId]);

  return (
    <>
      {loginConfig && loginConfig.type === LOGIN_CONFIG_TYPE.OKTA ? (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            {user ? (
              <Route path="/" element={botData && <MainPage botData={botData} />} />
            ) : (
              <Route path="/" element={botData && <HomePage botData={botData} />} />
            )}
            <Route path="/login/callback" element={<LoginCallback />} />
          </Routes>
        </Security>
      ) : (
        <Routes>
          <Route path="/" element={botData && <HomePage botData={botData} />} />
        </Routes>
      )}
    </>
  );
};

export default App;
