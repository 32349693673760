import './mainPage.scss';
import { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header/Header';
import WelcomePage from '../WelcomePage/WelcomePage';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ChatPage } from '../ChatPage/ChatPage';
import { v4 as uuidv4 } from 'uuid';
import { useAtom } from 'jotai';
import { allTagsAtom, selectedTagsAtom } from '../../atoms/tags';
import { questionAtom } from '../../atoms/chat';
import { createLog } from '../../api/logs';
import InactivityDetector from '../../components/InactivityDetector/InactivityDetector';

function MainPage({botData}) {
  const { botId } = useParams();
  const [selectedTags] = useAtom(selectedTagsAtom);
  const [isTyping, setIsTyping] = useState(false);
  const [isInputDisable, setIsInputDisable] = useState(false);
  const [, setAllTags] = useAtom(allTagsAtom);
  const [question, setQuesion] = useAtom(questionAtom);
  const [sessionId, setSessionId] = useState();
  const [exampleQuestions, setExampleQuestions] = useState([]);
  const [input, setInput] = useState('');
  const [allConversation, setAllConversation] = useState([]);
  const [placeHolder, setPlaceHolder] = useState('');
  const logData = useRef();
  const scrollEl = useRef(null);
  const exampleQuestionsList = useRef([]);
  const [userInActive, setUserInActive] = useState(false);
  const messageIndexRef = useRef({
    index: 0,
    question: '',
    answer: '',
    startTime: '',
  });
  const [botClientDetails, setBotClientDetails] = useState({
    botName: '',
    isEnglishUi: '',
    firstMessage: '',
  });

  useEffect(() => {
    if (!botData || !botData.login) {
      console.log('Bot data or login configuration is not ready yet.');
      return;
    }
    setUserConfiguration();
    setSessionId(uuidv4());
    logData.current = {};
  }, []);

  useEffect(() => {
    if (botClientDetails.endMessageValue) {
      if (botClientDetails.endMessageValue.isTimeout && userInActive && !isInputDisable) {
        let answer = {
          role: 'assistant',
          id: uuidv4(),
          isIgnore: true,
          content: botClientDetails.endMessageValue.content,
          sources: [],
          additionalLinks: [],
        };
        setAllConversation((prev) => [...prev, answer]);
        setIsInputDisable(true);
      }
    }
  }, [userInActive]);

  // adds the one question the user chose to the ref
  const handleQuestionPress = (id, content, item) => {
    exampleQuestionsList.current = [
      ...exampleQuestionsList.current,
      ...exampleQuestions.filter((item) => item.id == id),
    ];
    setExampleQuestions([]);

    getAns(content, true, { answer: item.answer, chunk: item.chunk });
  };

  useEffect(() => {
    if (allConversation.length > 1) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }

    const saveLogs = async () => {
      if (logData.current && Object.keys(logData.current).length !== 0) {
        try {
          logData.current.vectorRecommendations.forEach((vector, index) => {
            logData.current.vectorRecommendations[index] = vector.id;
          });
          if (logData.current.vectorRecommendations[0]) {
            console.log(logData.current.vectorRecommendations);
          }
          logData.current = {};
        } catch (error) {}
      }
    };
    saveLogs();
  }, [allConversation]);

  const setUserConfiguration = async () => {
    console.log('Bot Id:', botId);
    console.log('Bot Name:', botData.bot_name);
    console.log('Bot Data:', botData);
    console.log('Bot Tags:', botData.tags);

    setAllConversation([
      {
        id: uuidv4(),
        role: 'assistant',
        content: botData.text,
        isIgnore: true,
      },
    ]);

    const isEnglishUi = botData.isenglishui ? botData.isenglishui.includes('English') : true;
    setAllTags(!botData.tagsData ? [] : botData.tagsData);
    setBotClientDetails({
      endMessageValue: botData.endMessageValue,
      firstMessage: botData.text,
      isEnglishUi: isEnglishUi,
      errorMessage: botData.errorMessage,
      botName: botData.bot_name,
      isShowSourceText: botData.questions_selected.includes('Display the source text'),
      isShowSourceLink: botData.questions_selected.includes('Display the source link'),
      additionalLinksTitle: botData.additional_links_title,
    });
    setPlaceHolder(isEnglishUi ? 'Type here...' : 'מקלידים כאן...  ');

    sessionStorage.removeItem('relevant-answer-user-wants');
  };

  const getAns = async (content, isFollowUp, item) => {
    const userInput = content ? content : input;
    messageIndexRef.current.question = userInput;
    messageIndexRef.current.startTime = performance.now();
    messageIndexRef.current.index = messageIndexRef.current.index + 1;
    setQuesion({
      question: messageIndexRef.current.question,
      index: messageIndexRef.current.index,
      sessionId: sessionId,
    });
    const controller = new AbortController();
    try {
      if (!userInput || isTyping) return;
      // if no answer from the server cancel the api request and try again
      const timeout = setTimeout(() => {
        let answer = {
          content: `I'm trying again, can you stay with me or click on a new conversation`,
          role: 'ai',
          id: uuidv4(),
          ignore: true,
        };
        controller.abort();
        setAllConversation((prev) => [...prev, answer]);
        setIsTyping(false);
        return;
      }, 1000 * 30);
      clearTimeout(timeout);
      logData.current = {};

      setInput('');
      setIsTyping(true);
      let newMessage = {
        role: 'user',
        content: userInput,
        id: uuidv4(),
        isIgnore: false,
      };
      let newConversation = [...allConversation, newMessage];
      setAllConversation((prev) => [...prev, newMessage]);

      if (placeHolder) setPlaceHolder('');

      // console.log(botId)
      // console.log('user input: ', userInput)

      const cleanUserInput = userInput.replace(/[^\u0590-\u05FFa-zA-Z0-9 ]/g, ' ')
      const relevantUserWants = sessionStorage.getItem('relevant-answer-user-wants') || '';
      const botFlowUserWants = relevantUserWants.length ? `${relevantUserWants}, ${cleanUserInput}` : cleanUserInput;
      const { data } = await axios.post(
        `/api/get-answer/${botId}`,
        // `/api//assistant/${botId}/completion`,
        {
          sessionId: sessionId,
          messageIndex: messageIndexRef.current,
          conversation: newConversation,
          input: userInput,
          // tags: selectedTags.map((tag) => tag.tag_content),
          tags: selectedTags.map((item) => item.id),
          followUp: { isFollowUp: isFollowUp, answer: item?.answer, chunk: item?.chunk },
          similarQuestionsList: exampleQuestionsList.current,
          botFlows: { ...botData.botFlows, relevantUserWants: botFlowUserWants },
        },
        { signal: controller.signal }
      );

      let {
        finalResponse,
        responseBeforeTranslate,
        isIgnore,
        messageToVectorSearch,
        hackPrompt,
        stage,
        allRetrievedSources,
        exampleQuestions,
        bestVectorFromSimilarity,
        additionalLinks,
        theSourcesToShow,
        isAnswerBasedOnBestGrade,
        // filteredAboveGradeSources,
        // finalSource,
        // relevantAnswerContent,
      } = data;

      if (messageToVectorSearch.length) {
        sessionStorage.setItem('relevant-answer-user-wants', messageToVectorSearch);
      }

      if (isIgnore !== false) {
        newConversation[newConversation.length - 1].isIgnore = true;
      }

      if (typeof finalResponse === 'object') {
        finalResponse = finalResponse.content;
      }

      await createLog({
        question: messageIndexRef.current.question,
        messageIndex: messageIndexRef.current.index,
        sessionId: sessionId,
        answer: finalResponse,
        botId,
      });

      console.log('Best vector from search:', bestVectorFromSimilarity);
      console.log('Hack prompt response:', hackPrompt);
      console.log(`The user wants:`, messageToVectorSearch);
      console.log('All sources with grades / cosine similarity:', allRetrievedSources);

      console.log('The sources to show', theSourcesToShow);
      console.log('Final response before translation:', responseBeforeTranslate);
      console.log(`Final response after translation:`, finalResponse);
      console.log(`Additional links:`, additionalLinks);
      console.log(`The stage:`, stage);

      clearTimeout(timeout);

      let answer = {
        role: 'assistant',
        id: uuidv4(),
        isIgnore,
        content: finalResponse,
        sources: isAnswerBasedOnBestGrade ? [theSourcesToShow[0]] : theSourcesToShow,
        additionalLinks,
      };
      setAllConversation((prev) => [...prev, answer]);

      if (exampleQuestions && exampleQuestions.length) {
        const formattedFollowUpQuestions = exampleQuestions.map((q) => {
          const quesMatch = q.content.match(/(?:question:|שאלה:)\s*([\s\S]*?)\s*(?:answer:|תשובה:)/i);
          const ansMatch = q.content.match(/(?:answer:|תשובה:)\s*([\s\S]*)/i);
          return {
            ...q,
            content: quesMatch ? quesMatch[1].trim() : '',
            answer: ansMatch ? ansMatch[1].trim() : '',
          };
        });

        if (formattedFollowUpQuestions) {
          setExampleQuestions([...formattedFollowUpQuestions]);
        }
      }
      setIsTyping(false);

      if (
        botClientDetails.endMessageValue &&
        botClientDetails.endMessageValue.isMessageLimitation &&
        allConversation.length / 2 > botClientDetails.endMessageValue.maxLength - 1
      ) {
        let answer = {
          role: 'assistant',
          id: uuidv4(),
          isIgnore,
          content: botClientDetails.endMessageValue.content,
          sources: [],
          additionalLinks: [],
        };
        setAllConversation((prev) => [...prev, answer]);
        setIsInputDisable(true);
      }
      return;
    } catch (error) {
      if (error.message === 'canceled') {
        console.log('again');
        getAns();
        return;
      }

      let newMessage = {
        role: 'user',
        content: userInput,
        id: uuidv4(),
        isIgnore: true,
      };

      setAllConversation((prev) => {
        // Exclude the last item from the prev array
        const updatedPrev = prev.slice(0, -1);

        // Add the new message to the updated array
        return [...updatedPrev, newMessage];
      });

      let answer = {
        role: 'assistant',
        id: uuidv4(),
        isIgnore: true,
        content: botClientDetails.errorMessage,
        sources: [],
      };
      setAllConversation((prev) => [...prev, answer]);
      setIsInputDisable(false);
      setIsTyping(false);
      console.log(error);
    }
  };
  const handleInactivity = () => {
    setUserInActive(true);
    // Perform your action here
  };

  return (
    <div className="main-page">
      {botClientDetails.endMessageValue &&
        botClientDetails.endMessageValue.is_selected &&
        botClientDetails.endMessageValue.isTimeout && (
          <InactivityDetector
            timeout={botClientDetails.endMessageValue.timeLimited * 60 * 1000}
            onInactive={handleInactivity}
          />
        )}
      <Header isEnglishUi={botClientDetails.isEnglishUi} />

      {allConversation.length > 1 || isTyping ? (
        <ChatPage
          question={question}
          placeHolder={placeHolder}
          isTyping={isTyping}
          isInputDisable={isInputDisable}
          allConversation={allConversation}
          getAns={getAns}
          input={input}
          botData={botData}
          botId={botId}
          setInput={setInput}
          scrollEl={scrollEl}
          setAllConversation={setAllConversation}
          isEnglishUi={botClientDetails.isEnglishUi}
          isLimit={isInputDisable}
          messageIndex={messageIndexRef.current.index}
          sessionId={sessionId}
          exampleQuestions={exampleQuestions}
          handleQuestionPress={handleQuestionPress}
          isShowSourceLink={botClientDetails.isShowSourceLink}
          isShowSourceText={botClientDetails.isShowSourceText}
          additionalLinks={botClientDetails.additionalLinks}
          additionalLinksTitle={botClientDetails.additionalLinksTitle}
        />
      ) : (
        <WelcomePage
          firstMessage={botClientDetails.firstMessage}
          placeHolder={placeHolder}
          getAns={getAns}
          input={input}
          setInput={setInput}
          isEnglishUi={botClientDetails.isEnglishUi}
        />
      )}
    </div>
  );
}

export default MainPage;
