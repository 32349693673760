import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import Snackbar from '../../components/Snackbar/Snackbar';
import { useSnackbar } from 'notistack';
import MainPage from '../MainPage/MainPage';
import LoginPage from '../LoginPage/LoginPage';
import LoginPasswordPage from '../LoginPage/LoginPasswordPage';
import LoginOkta from '../../components/Login/LoginOkta';
import { loginConfigAtom, finishedLoginAtom, userAtom } from '../../atoms/users';
import WelcomeLoginPage from '../WelcomeLoginPage/WelcomeLoginPage';
import { LOGIN_METHOD_TYPE } from '../../constants/enums';

function HomePage({ botData }) {
  const [isFinishedLogin] = useAtom(finishedLoginAtom);
  const [loginConfig] = useAtom(loginConfigAtom);
  const [user] = useAtom(userAtom);
  const [isLoginRequired, setIsLoginRequired] = useState(false);
  const isLoggedIn = sessionStorage.getItem('assistant-login-config');
  const [isWelcome, setIsWelcome] = useState(true);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const setAssistantConfig = async () => {
      try {
        if (!botData || !botData.login) {
          throw new Error('Bot data or login configuration is not ready yet.');
        }
        const { is_login: isLogin } = botData.login;

        setLoading(false);
        setIsLoginRequired(isLogin);
      } catch (error) {
        enqueueSnackbar('We encountered a problem Connecting to assistant, please try again', {
          content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
      }
    };

    setAssistantConfig();
  }, []);

  useEffect(() => {
    if (isLoginRequired && isFinishedLogin) {
      sessionStorage.setItem('assistant-login-config', loginConfig.method);
      console.log(`User login using method '${loginConfig.method.toUpperCase()}' was successful`);
      enqueueSnackbar(`Connection to Assistant was successful`, {
        content: (key, message) => <Snackbar type={'success'} message={message} />,
      });
    }
  }, [isLoginRequired, isFinishedLogin]);

  switch (true) {
    case !isLoginRequired ||
      isFinishedLogin ||
      !loginConfig ||
      user ||
      (isLoggedIn && isLoggedIn.length > 0):
      return <MainPage botData={botData} />;
    case isLoginRequired:
      switch (true) {
        case loginConfig.method === LOGIN_METHOD_TYPE.PROVIDER:
          return isWelcome ? <WelcomeLoginPage setIsWelcome={setIsWelcome} /> : <LoginPage />;
        case loginConfig.method === LOGIN_METHOD_TYPE.PASSWORD && loginConfig.hasPassword:
          return isWelcome ? (
            <WelcomeLoginPage setIsWelcome={setIsWelcome} />
          ) : (
            <LoginPasswordPage botId={botData.bot_id} />
          );
        case loginConfig.method === LOGIN_METHOD_TYPE.NONE:
          return isWelcome ? (
            <WelcomeLoginPage setIsWelcome={setIsWelcome} />
          ) : (
            <MainPage botData={botData} />
          );
        default:
          return isWelcome ? (
            <WelcomeLoginPage setIsWelcome={setIsWelcome} />
          ) : (
            <MainPage botData={botData} />
          );
      }
    case isLoginRequired && loginConfig.type === 'OKTA':
      return <LoginOkta />;
    default:
      return <>Loading...</>;
  }
}

export default HomePage;
