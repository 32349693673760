import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import createAuthProviderService from '../../../services/authProviderService';
import { finishedLoginAtom, loginConfigAtom } from '../../../atoms/users';

export const ActiveDirectoryProvider = ({ handleLogin, loginUserWithData }) => {
  const [, setFinishedLogin] = useAtom(finishedLoginAtom);
  const [config] = useAtom(loginConfigAtom);

  const authProviderService = createAuthProviderService();
  const provider = authProviderService.createProvider(config.type, config.clientPayload);

  useEffect(() => {
    const handleLoginProvider = async () => {

      try {
        await provider.initialize();
        const activeDirectoryResponse = await provider.login();

        if (activeDirectoryResponse) {
          
          // Login to jeen and create jeen token
          const isLogin = await handleLogin({
            email: activeDirectoryResponse.account.username.toLowerCase(),
            providerToken: activeDirectoryResponse.accessToken,
          });
          setFinishedLogin(true);
          
          if (!isLogin) {
            console.log('Failed to login to jeen');
            return false;
          }
          // loginUserWithData();
        }
      } catch (err) {
        console.log(err);
        // navigate('/');
      }
    };
    handleLoginProvider();
  }, []);

  return <></>;
};
