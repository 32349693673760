import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import createAuthProviderService from '../../../services/authProviderService';
import { finishedLoginAtom, loginConfigAtom } from '../../../atoms/users';
import { useOktaAuth } from '@okta/okta-react';

export const OktaProvider = ({ handleLogin, loginUserWithData }) => {
  const [config] = useAtom(loginConfigAtom);
  const [, setFinishedLogin] = useAtom(finishedLoginAtom);

  const authProviderService = createAuthProviderService();
  const provider = authProviderService.createProvider(config.type, config.clientPayload);
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    const handleLoginProvider = async () => {

      try {
        await provider.initialize();
        const loginResponse = await provider.login(authState);
        // const isAuthenicated = provider.isAuthenticated(authState);

        if (!authState || !oktaAuth) {
          return <div>There was an auth issue...</div>;
        }
        if (loginResponse.isSuccess) {
          
          // Login to jeen and create jeen token
          const isLogin = await handleLogin({
            email: loginResponse.userMail.toLowerCase(),
            providerToken: loginResponse.providerToken,
          });
          setFinishedLogin(true);

          if (!isLogin) {
            console.log('Failed to login to jeen');
            return false;
          }
          // loginUserWithData();
        } else {
          localStorage.clear();
          // navigate('/');
        }
      } catch (err) {
        console.log(err);
        // navigate('/');
      }
    };
    handleLoginProvider();
  }, []);

  return <></>;
};
