import './header.scss';
import '../ToggleBtn/toggle-btn.scss';
import { ReactComponent as MainLogo } from '../../assets/main-logo.svg';
import { ReactComponent as NewConversationBtn } from '../../assets/new-conversation-btn.svg';
import { allTagsAtom } from '../../atoms/tags';
import { useAtom } from 'jotai';

function Header({ isEnglishUi }) {
  const [allTags] = useAtom(allTagsAtom);
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <div className="header" style={{ flexDirection: isEnglishUi ? 'row-reverse' : 'row' }}>
      <MainLogo style={{ cursor: 'auto' }} />
      <div className="left-side">
        <div className="new-conversation-container" onClick={refreshPage}>
          {isEnglishUi ? <p>New Chat</p> : <p>שיחה חדשה</p>}
          <NewConversationBtn className="new-conversation-btn" />
        </div>
      </div>
    </div>
  );
}

export default Header;
