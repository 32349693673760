export const LOGIN_CONFIG_TYPE = {
  FIREBASE: 'FIREBASE',
  ACTIVE_DIRECTORY: 'ACTIVE_DIRECTORY',
  OKTA: 'OKTA',
};
export const LOGIN_METHOD_TYPE = {
  PROVIDER: 'provider',
  PASSWORD: 'password',
  NONE: 'none',
};
