import React, { useEffect, useState, useRef } from 'react';

function InactivityDetector({ timeout, onInactive }) {
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const timerRef = useRef(null);

  const resetTimer = () => {
    setLastInteractionTime(Date.now());
  };

  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keypress', 'touchstart'];

    const eventHandler = () => resetTimer();

    events.forEach((event) => {
      window.addEventListener(event, eventHandler);
    });

    timerRef.current = setInterval(() => {
      if (Date.now() - lastInteractionTime >= timeout && !hasRunOnce) {
        onInactive();
        setHasRunOnce(true); // Ensure the action only runs once
        clearInterval(timerRef.current); // Clear the interval after inactivity action is triggered
      }
    }, 1000);

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, eventHandler);
      });
      clearInterval(timerRef.current);
    };
  }, [lastInteractionTime, timeout, onInactive, hasRunOnce]);

  return null; // This component does not render anything
}

export default InactivityDetector;
