import { getLoginConfig, getLoginPassword } from '../api/auth';
import { LOGIN_METHOD_TYPE } from '../constants/enums';

export async function setAssistantLoginConfig(method, provider, botId) {
  if (!method) {
    console.log('Login method is incorrect');
    return;
  }
  const loginType = method.toLowerCase();

  switch (true) {
    case loginType === LOGIN_METHOD_TYPE.PROVIDER:
      const loginConfig = await getLoginConfig(provider);
      return loginConfig;
    case loginType === LOGIN_METHOD_TYPE.PASSWORD:
      const loginPass = await getLoginPassword(botId);
      return loginPass;
    case loginType === LOGIN_METHOD_TYPE.NONE:
      return null;
    default:
      return null;
  }
}
