import React, { useState } from 'react';
import { useAtom } from 'jotai';
import createAuthProviderService from '../../../services/authProviderService';
import { finishedLoginAtom, loginConfigAtom } from '../../../atoms/users';
import Login from '../../Login/Login';

export const FirebaseProvider = ({ handleLogin, loginUserWithData }) => {
  const [, setFinishedLogin] = useAtom(finishedLoginAtom);
  const [config] = useAtom(loginConfigAtom);
  const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });

  const authProviderService = createAuthProviderService();
  const provider = authProviderService.createProvider(config.type, config.clientPayload);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setLoginInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleLoginProvider = async (e) => {
    e.preventDefault();

    try {
      const fireBaseResponse = await provider.login({
        email: loginInfo.email,
        password: loginInfo.password,
      });
      if (fireBaseResponse) {
        const fbUser = fireBaseResponse.user;
        if (fbUser) {

          // Login to jeen and create jeen token
          const isLogin = await handleLogin({ email: fbUser.email, providerToken: fbUser.accessToken });
          setFinishedLogin(true);

          if (!isLogin) {
            console.log('User was unable to login to jeen');
            return false;
          }
          // loginUserWithData();
        }
      }
    } catch (err) {
      console.log(err);
      // navigate('/');
    }
  };

  return <Login loginForm={loginInfo} handleLogin={handleLoginProvider} handleChange={handleChange} />;
};
