import './welcomePage.scss';
import InputField from '../../components/InputField/InputField';
import TagsDropdown from '../../components/Tags/TagsDropdown/TagsDropdown';
import { allTagsAtom } from '../../atoms/tags';
import { useAtom } from 'jotai';
import TagList from '../../components/Tags/NewTags/NewTags';

function WelcomePage({ input, setInput, getAns, placeHolder, firstMessage, isEnglishUi }) {
  const [allTags] = useAtom(allTagsAtom);
  return (
    <div
      className="welcome-page"
      style={isEnglishUi ? { paddingLeft: '83px', direction: 'ltr' } : { paddingRight: '83px' }}
    >
      <p className="first-message">{firstMessage}</p>
      {allTags && typeof allTags === 'object' && Object.keys(allTags).length > 0 && <TagList />}
      <InputField
        isEnglishUi={isEnglishUi}
        placeHolder={placeHolder}
        getAns={getAns}
        input={input}
        setInput={setInput}
      />
      {allTags.length > 0 && <TagsDropdown />}
    </div>
  );
}

export default WelcomePage;
