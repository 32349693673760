import axios from 'axios';

let axiosInstance = null;

function createAxiosInstance() {
  if (axiosInstance) {
    return axiosInstance;
  }
  console.warn('Creating axios instance');
  axiosInstance = axios.create({ withCredentials: true });
  const sessionJwtToken = sessionStorage.getItem('token');
  if (sessionJwtToken) {
    console.log('Setting axios token from session');
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionJwtToken}`;
  }

  axiosInstance.updateAxiosToken = function (token) {
    console.warn('Updating axios token');
    sessionStorage.setItem('token', token);
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  return axiosInstance;
}

export default createAxiosInstance();
