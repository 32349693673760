// import axios from '@/configs/axios.js';
import axios from 'axios';

export const getLoginConfig = async (provider) => {
  try {
    const { data } = await axios.post(`/api/auth/providers/get-config`, {
      provider: provider,
    });
    return data;
  } catch (error) {
    console.error('Failed fetching login provider:', error);
    throw error;
  }
};

export const getLoginPassword = async (botId) => {
  try {
    const { data } = await axios.get(`/api/auth/login-password/${botId}`);
    return data;
  } catch (error) {
    console.error('Failed fetching login password:', error);
    throw error;
  }
};

export const validateLoginPassword = async (password, botId) => {
  try {
    const { data } = await axios.put(`/api/auth/validate-password/${botId}`, {
      inputPassword: password,
    });
    return data;
  } catch (error) {
    console.error('Failed validating login password:', error);
    throw error;
  }
};
