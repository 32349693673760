import { OktaAuth } from '@okta/okta-auth-js';
// import { useOktaAuth } from '@okta/okta-react';

class OktaStore {
  constructor(config) {
    if (!config) {
      throw new Error('Okta configuration is required.');
    }
    const { clientId, issuer, redirectUri } = config;
    this.oktaAuth = new OktaAuth({
      clientId: clientId,
      issuer: issuer,
      redirectUri: window.location.origin + redirectUri,
    });
  }

  async initialize() {
    try {
      this.oktaAuth.signInWithRedirect();
    } catch (error) {
      alert('username or password are invalid');
      console.error('Login failed:', error);
      throw new Error('Invalid credentials or login issue.');
    }
  }

  async login() {
    try {
      // if (authState.isAuthenticated) {
      const okta = JSON.parse(localStorage.getItem('okta-token-storage'));
      if (okta && okta.accessToken) {
        const userMail = okta.accessToken.claims.sub;
        if (okta.accessToken.expiresAt < Date.now() / 1000) {
          return { isSuccess: false, userMail: '', providerToken: '' };
        }
        return { isSuccess: true, userMail: userMail, providerToken: okta.accessToken };
      }
      // }
      return { isSuccess: false, userMail: '', providerToken: '' };
    } catch (error) {
      console.error('Login failed:', error);
    }
  }

  // isAuthenticated(authState) {
  //   return authState.isAuthenticated();
  // }
}

export default OktaStore;
