export function SessionStorageHandler() {
  const TAB_KEY = 'uniqueTabId';
  const REFRESH_KEY = 'tabTimestamp';

  const generateRandomString = (length = 16) => {
    return Array.from(crypto.getRandomValues(new Uint8Array(length)))
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');
  };

  const initializeTab = () => {
    let currentTabId = sessionStorage.getItem(TAB_KEY);
    let tabTimestamp = sessionStorage.getItem(REFRESH_KEY);

    if (!currentTabId || !tabTimestamp) {
      currentTabId = generateRandomString();
      tabTimestamp = Date.now().toString();

      sessionStorage.clear();
      sessionStorage.setItem(TAB_KEY, currentTabId);
      sessionStorage.setItem(REFRESH_KEY, tabTimestamp);

      //   console.log('New/Duplicated Tab Initialized.');
    } else {
      //   console.log('Refreshed Tab Detected.');
    }

    return currentTabId;
  };

  const getTabState = () => {
    const tabId = sessionStorage.getItem(TAB_KEY);
    const timestamp = sessionStorage.getItem(REFRESH_KEY);
    if (!tabId || !timestamp) {
      initializeTab();
      return 'new-tab';
    }
    return 'refreshed-tab';
  };

  const currentTabId = initializeTab();

  return {
    getTabId: () => currentTabId,
    getTabState: () => getTabState(),
    setItem: (key, value) => {
      try {
        const data = typeof value === 'string' ? value : JSON.stringify(value);
        sessionStorage.setItem(key, data);
      } catch (error) {
        console.error(`Failed to set item "${key}" in session storage:`, error);
      }
    },
    getItem: (key) => {
      try {
        const value = sessionStorage.getItem(key);
        if (!value) return null;

        try {
          return JSON.parse(value);
        } catch {
          return value;
        }
      } catch (error) {
        console.error(`Failed to get item "${key}" from session storage:`, error);
        return null;
      }
    },
    removeItem: (key) => {
      try {
        sessionStorage.removeItem(key);
      } catch (error) {
        console.error(`Failed to remove item "${key}" from session storage:`, error);
      }
    },
    clear: () => {
      try {
        sessionStorage.clear();
      } catch (error) {
        console.error('Failed to clear session storage:', error);
      }
    },
  };
}
