import axios from '../configs/axios.js';

export async function loginUser(email) {
  const { data } = await axios.put('/api/user/login', {
    email,
  });
  return data;
}

export async function validateUserPermission(email, botId) {
  const { data } = await axios.put('/api/user/validate', {
    email,
    botId,
  });
  return data;
}
