import { atom } from 'jotai';
import axios from '../configs/axios.js';
import { atomWithStorage } from 'jotai/utils';
import createAuthProviderService from '../services/authProviderService';

export const userAtom = atomWithStorage('user', '', undefined, { getOnInit: true });
export const loginConfigAtom = atom({});

export const finishedLoginAtom = atom(false);
export const makeSureLoginAtom = atom(null, async (get, set, navigate) => {
  const config = get(loginConfigAtom);

  const authProviderService = createAuthProviderService();
  const provider = authProviderService.createProvider(config.type, config.clientPayload);

  const auth = await provider.getAuthInstance();

  auth.onAuthStateChanged(async (user) => {
    if (user) {
      const tokenResult = await user.getIdTokenResult(true);
      console.log({ tokenResult });
      if (tokenResult.claims) {
        // console.log(tokenResult.claims)

        // }
        const { data } = await axios.put('/api/user/login', {
          email: tokenResult.claims.email,
        });
        const { user } = data;

        set(userAtom, {
          id: user.id.trim(), ///okta.accessToken.claims.uid,
          email: tokenResult.claims.email, ///okta.accessToken.claims.sub,
          userName: user.name, //okta.accessToken.claims.sub,
          lang: 'english',
        });

        set(finishedLoginAtom, true);

        // console.log(!window.location.href.includes('register') && !window.location.href.includes('turbo') && !window.location.href.includes('data'))

        !window.location.href.includes('/') &&
          !window.location.href.includes('register') &&
          !window.location.href.includes('turbo') &&
          !window.location.href.includes('data') &&
          navigate.push('/playground');
      }
    } else if (!window.location.href.includes('register') && !window.location.href.includes('turbo')) {
      navigate.push('/login');
    }
  });
});
