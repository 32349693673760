import './new-tags.scoped.css';
import React from 'react';
import { useAtom } from 'jotai';
import { allTagsAtom, selectedTagsAtom } from '../../../atoms/tags';

// Sample data structure
// const tagData = {
//   elior: {
//     id: 280,
//     children: {
//       'ProAero Air': {
//         id: 282,
//         children: {},
//       },
//       Nylon: {
//         id: 281,
//         children: {},
//       },
//     },
//   },
//   oren: {
//     id: 290,
//     children: {
//       Ping: {
//         id: 292,
//         children: {
//           aloha: {
//             id: 293,
//             children: {},
//           },
//         },
//       },
//       pong: {
//         id: 291,
//         children: {},
//       },
//     },
//   },
// }

// Tag component for each level
function Tag({ name, node, selectedTags, setSelectedTags, level, style }) {
  const isSelected = selectedTags[level]?.name === name;

  const handleClick = () => {
    if (isSelected) {
      setSelectedTags((prev) => prev.slice(0, level));
    } else {
      const newSelectedTag = { name, id: node.id };
      setSelectedTags((prev) => [...prev.slice(0, level), newSelectedTag]);
    }
  };

  return (
    <div className="tag-wrapper">
      <div style={isSelected ? style : {}} onClick={handleClick} className={`single-tag`}>
        {name}
      </div>
    </div>
  );
}

// Main component to render the tree
function TagTree() {
  //   const [selectedTags, setSelectedTags] = useState([])
  const [tagsAtom] = useAtom(allTagsAtom);
  const [selectedTags, setSelectedTags] = useAtom(selectedTagsAtom);

  // Get selected nodes for each level
  const level0Tags = Object.entries(tagsAtom);
  const level1Tags = selectedTags[0]
    ? Object.entries(tagsAtom[selectedTags[0].name]?.children || {})
    : [];
  const level2Tags = selectedTags[1]
    ? Object.entries(tagsAtom[selectedTags[0].name]?.children[selectedTags[1].name]?.children || {})
    : [];

  return (
    <div>
      <h2>Tags</h2>
      <div className="tags-container-main">
        {/* Level 0 Tags */}
        <div className="tags-container-level">
          {level0Tags.map(([name, node]) => (
            <Tag
              key={node.id}
              name={name}
              node={node}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              level={0}
              style={{ backgroundColor: 'var(--tags-level-one-clr)' }}
            />
          ))}
        </div>

        {/* Level 1 Tags */}
        {level1Tags.length > 0 && (
          <div className="tags-container-level">
            {level1Tags.map(([name, node]) => (
              <Tag
                key={node.id}
                name={name}
                node={node}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                level={1}
                style={{ backgroundColor: 'var(--tags-level-two-clr)' }}
              />
            ))}
          </div>
        )}

        {/* Level 2 Tags */}
        {level2Tags.length > 0 && (
          <div className="tags-container-level">
            {level2Tags.map(([name, node]) => (
              <Tag
                key={node.id}
                name={name}
                node={node}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                level={2}
                style={{ backgroundColor: 'var(--tags-level-three-clr)' }}
              />
            ))}
          </div>
        )}
      </div>

      {/* <h3>Selected Path:</h3>
      <pre>{JSON.stringify(selectedTags, null, 2)}</pre> */}
    </div>
  );
}

export default TagTree;
