import './login.scoped.scss';
import React, { useMemo, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { propertiesAtom } from '../../atoms/general';
import { isMobileAtom } from '../../atoms/general';
import { useAtom } from 'jotai';

export const Login = ({ loginForm, handleLogin, handleChange }) => {
  const [isMobile] = useAtom(isMobileAtom);
  const [properties] = useAtom(propertiesAtom);
  const [logoUrls, setLogoUrls] = useState({});
  const isPasswordLogin = 'email' in loginForm;

  useMemo(() => {
    setLogoUrls({
      login_logo: properties.urls?.logo_urls['login-logo'],
      credit_logo: properties.urls?.logo_urls['credit-logo'],
    });
  }, [properties]);

  return (
    <div className="login-page">
      <div className="modal-full-screen">
        <div
          className="modal-container"
          style={isMobile ? { width: '90%', overflowY: 'visible' } : { width: '55%', overflowY: 'auto' }}
        >
          <div className="modal-upper">
            {logoUrls.login_logo ? (
              <img
                src={logoUrls.login_logo}
                alt="onebi logo"
                style={isMobile ? { width: '180px' } : { width: '253px' }}
              />
            ) : (
              <div className="loading-icon-login">
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    left: '46%',
                    color: properties?.colors?.main_colors['--main-clr'] || `red`,
                  }}
                  size="50px"
                />
              </div>
            )}
          </div>
          <div style={isMobile ? { width: '300px' } : { width: 'auto' }}>
            <h2>Jeen.AI Your AI environment</h2>
          </div>

          <div className="form-container">
            <form onSubmit={handleLogin}>
              {isPasswordLogin && (
                <input
                  value={loginForm.email}
                  style={isMobile ? { width: '230px' } : { width: '490px' }}
                  name="email"
                  onChange={handleChange}
                  type="text"
                  placeholder="email"
                />
              )}
              <input
                value={loginForm.password}
                style={isMobile ? { width: '230px' } : { width: '490px' }}
                name="password"
                onChange={handleChange}
                type="password"
                placeholder="password"
              />
              <button
                onClick={handleLogin}
                className="login-btn"
                disabled={
                  isPasswordLogin
                    ? loginForm.password === ''
                    : loginForm.email === '' || loginForm.password === ''
                }
              >
                Login
              </button>
            </form>
          </div>
        </div>
        <div
          className="bottom-credit"
          style={isMobile ? { left: 'fit-content' } : { left: 'fit-content%' }}
        >
          <h4>Created By</h4>
          {logoUrls.credit_logo ? (
            <img src={logoUrls.credit_logo} alt="onebi logo" />
          ) : (
            <div className="loading-icon-credit">
              <CircularProgress
                sx={{
                  position: 'absolute',
                  left: '100%',
                  bottom: '0%',
                  color: properties?.colors?.main_colors['--main-clr'] || `rgba(249, 109, 223, 1)`,
                }}
                size="20px"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
